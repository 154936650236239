// @flow

import * as React from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import {
  ANALYTICS,
  BREAKPOINT,
  COLOR,
  FONT_WEIGHT,
  FONT_SIZE,
  LINE_HEIGHT,
} from '@latitude/core/utils/constants';
import { Heading1 } from '@latitude/heading';
import { Text } from '@latitude/text';
// import Breadcrumb from '@latitude/breadcrumb';
import LSHAPE_KIWIBANK from './img/lshape-kiwibank.png';

type Props = {
  heading: string,
  breadcrumb?: boolean,
  subHeading?: string
};

const SecondaryHero = ({ heading, subHeading, breadcrumb }: Props) => (
  <AnalyticsLocationProvider location={ANALYTICS.LOCATION.HERO}>
    <HeroContainer>
      {/* {breadcrumb && <Breadcrumb />} */}
      <LFrameBackground />
      <ContentArea className={breadcrumb && 'breadcrumb'}>
        <ContentInner>
          <Heading color={COLOR.BLACK}>{heading}</Heading>
          {subHeading && <SubHeading>{subHeading}</SubHeading>}
        </ContentInner>
      </ContentArea>
    </HeroContainer>
  </AnalyticsLocationProvider>
);

const HeroContainer = styled.div`
  background: ${COLOR.GREY4};
  height: 180px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT.MD}) {
    height: 272px;
  }
`;

const ContentArea = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: calc(${BREAKPOINT.MD} - 30px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: calc(${BREAKPOINT.LG} - 30px);
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    max-width: calc(1140px - 30px);
  }

  &.breadcrumb {
    align-self: baseline;
  }
`;

const ContentInner = styled.div`
  width: 100%;
  padding: 0 16px;

  @media (min-width: ${BREAKPOINT.MD}) {
    width: 66.6667%;
    padding: 0;
  }
`;

const Heading = styled(Heading1)`
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 5px;

  @media (min-width: ${BREAKPOINT.MD}) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const SubHeading = styled(Text)`
  font-weight: ${FONT_WEIGHT.LIGHT};
  line-height: ${LINE_HEIGHT.STANDARD.SMALL};
  font-size: ${FONT_SIZE.NORMAL};

  @media (min-width: ${BREAKPOINT.MD}) {
    line-height: ${LINE_HEIGHT.STANDARD.LARGE};
    font-size: ${FONT_SIZE.LARGE};
  }
`;

const LFrameBackground = styled.div`
  background: url(${LSHAPE_KIWIBANK}) no-repeat;
  background-position: center center 15px;
  background-size: 900px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (min-width: ${BREAKPOINT.XS}) {
    background-position: 50vw 0;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    font-size: 48px;
    line-height: 56px;
    background-size: auto;
  }
`;

export default SecondaryHero;
