import React from 'react';
import styled from 'styled-components';
import { Box } from '@latitude/box';
import {
  MARGIN,
  PADDING,
  FONT_SIZE,
  BREAKPOINT,
  FLEX_DIRECTION,
  DISPLAY,
  JUSTIFY_CONTENT,
  COLOR,
  ALIGN,
  LINE_HEIGHT
} from '@latitude/core/utils/constants';
import { Heading6 } from '@latitude/heading';
import { Metadata } from '@latitude/metadata';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { List, ListItem } from '@latitude/list';
import { SITE_URL } from '../../utils/constants';
import { Link } from '../../components/Link/Link';
import Layout from '../../components/layout-kiwibank';
import SecondaryHero from '../../components/Hero/HeroKiwibank';

const Privacy = ({ location }) => (
  <Layout location={location} noFooter>
    <main className="navigation-spacer">
      <Metadata
        title="Privacy &amp; Credit Reporting policy | Latitude for"
        description="Latitude Financial Services Limited take our obligations for protecting personal information seriously. We are bound by, and will abide by, the requirements of the Privacy Act 1993."
        canonical={`${SITE_URL}${location.pathname}`}
      />

      <SecondaryHero
        heading={
          <>
            Privacy and Credit <br />
            Reporting Policy
          </>
        }
        subHeading="Effective from March 2023"
      />

      <PrivacySection />
      <HorizontalRule
        css={`
          background-color: ${COLOR.TRANSPARENT};
        `}
      />
    </main>
    <StyledFooter>
      <div className="container">
        <StyledFooterInner>
          <div>
            <StyledDisclaimerText>
              Latitude Personal Loans are provided by Latitude Financial Services,
              8 Tangihua Street, Auckland, 1010 New Zealand.
            </StyledDisclaimerText>
          </div>
        </StyledFooterInner>
      </div>
    </StyledFooter>
  </Layout>
);

const PrivacySection = () => (
  <Box.Section backgroundColor={COLOR.WHITE} isBodyCopy>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Latitude Financial Services Limited (and our related entities)
        (&ldquo;Latitude&rdquo;), 8 Tangihua Street, Auckland Central,
        Auckland, New Zealand, take our obligations for protecting personal
        information seriously. We are bound by, and will abide by, the
        requirements of the Privacy Act 2020.
      </Text>
      <Text>
        This privacy policy sets out how we will collect, use, disclose and
        protect your personal information (&ldquo;Privacy Policy&rdquo;). You
        accept this Privacy Policy when you sign up for, access, or use our
        products or services, content, features, technologies or functions
        offered on our website or mobile apps.
      </Text>
      <Text>
        You may interact with us anonymously or by using a pseudonym if the
        interaction is general in nature. However, if the interaction is
        specific to an account or relates to your personal information, we will
        need to identify you before we can engage in further discussions and
        correspondence.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Changes to This Privacy Policy
    </Heading6>
    <Text>
      We reserve the right to modify or amend this Privacy Policy at any time
      and for any reason by uploading a revised policy onto our website. The
      change to the Privacy Policy will apply from the date that we upload the
      revised policy.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      What Personal Information do we Collect and Hold?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We offer a wide range of products and services and as a result we
        collect and hold a range of personal information from and about people
        (particularly people who wish to purchase our products or use our
        services).
      </Text>
      <Text>
        In the process of conducting our businesses, we are likely to collect a
        broad range of information about our customers, prospective customers,
        employees, prospective employees, contractors, suppliers, brokers,
        introducers, agents, service providers and the people who run the
        businesses we deal with. This information can include such things as
        contact details, financial information and supporting documentation
        (including credit history details), identification information,
        transaction history information, billing information, banking details
        and personal references. In order to satisfy our legal obligations,
        we may need to retain that information even after a transaction has
        come to an end (subject to our obligations under the Privacy Act or
        any other applicable Act).
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      How we Collect Personal Information?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Wherever it is possible and practical to do so, we collect personal
        information directly from the person concerned. For example, we will
        collect personal information when a person deals with us in person or
        over the telephone, sends us a letter, e-mail or fax, or gives or sends
        us a completed form (such as an application form). There may be
        occasions, however, where we need to collect personal information we
        require for a particular purpose from a third party. This might occur,
        for example, where it is important for us to obtain personal information
        from an independent third party or from a Merchant who you choose to use
        one of our services or products through.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Why do we Collect and Hold Personal Information?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We collect this information in order to undertake and complete the many
        transactions we have with our customers, suppliers, and other parties
        with whom we do business, to administer the products we provide and to
        maintain the business relationships we have that enable us to carry on
        our business. Personal information collected may also be used to:
      </Text>
      <List dotColor={COLOR.GREY75}>
        <ListItem>provide you with product(s) or service(s),</ListItem>
        <ListItem>verify your identity,</ListItem>
        <ListItem>improve the services and products we provide,</ListItem>
        <ListItem>
          keeping our customers informed of the products and services we have,
          which may include using it for direct marketing purposes,
        </ListItem>
        <ListItem>
          to meet our legal and regulatory requirements under various legislations
          such as the Privacy Act 2020, Anti-Money Laundering and Countering Financing
          of Terrorism Act, Credit Contracts and Consumer Finance Act, any other
          applicable legislation or regulation.
        </ListItem>
      </List>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      To what Other Organisations do we Disclose Personal Information?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        In providing and managing our products and services, we contract with
        other businesses and may disclose personal information to them in the
        process. These organisations provide services to us and may need access
        to the personal information we hold in order to enable them to perform
        those services. We require these companies to adhere to our strict
        confidentiality requirements for handling personal information and also
        seek to ensure that they adhere to the requirements of the Privacy Act.
      </Text>
      <Text>
        We may disclose your personal information to third parties , such as
        advisors, lawyers, accountants, acquirers (or potential acquirers) of
        all or any part of our business (including our related entities within
        the Latitude Group), or resellers who sell our services, or business
        partners with whom we exchange customer data for the purposes of
        providing our services to you, including to debt collection agencies for
        the purposes of collecting debts, or for the purposes of due diligence
        enquiries or in order to run the business or part of the business
        acquired.
      </Text>
      <Text>
        We will disclose your information to any person authorised by you any
        other person authorised by the Act or another law (e.g. a law
        enforcement agency).
      </Text>
      <Text>
        Some of the third parties to which we may disclose your personal
        information may be located outside of New Zealand. Where they are
        located outside of New Zealand we ensure they will handle your Personal
        Information in accordance to the Privacy Act.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      How to Access and Correct the Personal Information we Hold About you?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        If you wish to obtain details of your personal information that we hold,
        please contact using the &apos;Contact Us&apos; table at the end of this policy.
        There is no charge to request access to your personal information.
        Subject to certain grounds, we may refuse access to your data, however,
        we will advise you the reasons why.
      </Text>
      <Text>
        If you wish to request correction to the personal information we hold,
        we will correct your personal information. If we do not make the
        correction, we will take reasonable steps to note on the personal
        information that you requested the correction.
      </Text>
      <Text>
        To correct your personal information please contact us using the table
        below. Your request should provide evidence of who you are and set out
        the details of your request (e.g. the personal information, or the
        correction, that you are requesting).
      </Text>
      <Text>
        We may charge you our reasonable costs of providing to you copies of
        your personal information or correcting that information.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      How you can Make a Complaint Regarding your Personal Information?
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        If you believe that we have failed to comply with the Privacy Act,
        please contact us by using the table below and we will then follow
        our Internal Dispute Resolution process to review your concerns.
      </Text>
      <Text>
        If the complaint remains unresolved, you may refer it to Privacy
        Commissioner who will independently and impartially review and resolve
        the dispute.
      </Text>
      <Text>The contact details for the Privacy Commissioner are:</Text>
      <Text>
        <strong>Office of the Privacy Commissioner</strong>
        <br />
        PO Box 10094
        <br />
        Wellington 6143
        <br />
        Phone: 0800 803 909
        <br />
        Fax: 04 474 7595
        <br />
        Website:&nbsp;
        <Link
          href="http://www.privacy.org.nz"
          target="_blank"
          rel="noopener nofrefferer"
          css="color: #000; border-bottom: 0px #000;"
        >
          www.privacy.org.nz
        </Link>
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Your Personal Information and emails
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        If you send us an e-mail we will store and make use of your name,
        address and other information about you contained in your e-mail and in
        any attachments. When you submit feedback or questions via e-mail, it is
        very important that you do not disclose any details that could be used
        by others to gain access to your account. This includes private details
        such as your username, password or card number.
      </Text>
      <Text>
        If you receive an e-mail from us, you should only re-transmit,
        distribute or commercialise the material or information in the e-mail if
        you are authorised to do so (under the Privacy Act or otherwise).
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Your Personal Information on the Web
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We do not collect personal information about you when you visit our web
        sites unless you apply for credit online or register for access to our
        Latitude service portal.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Your Personal Information and Direct Marketing
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        By providing us with your personal information, you agree to us using
        that personal information for the purpose of informing you about our
        products and services, or providing your information to our related
        entities within the Latitude Group and third parties we have dealings
        with to market our products and services or to market their products and
        services to you. If you do not wish to receive marketing materials from
        us, please complete the opt-out provision on the marketing information
        sent to you or contact us using the table below. If you choose not to
        receive any marketing materials from us, you acknowledge you may miss
        out on special product and service offerings and opportunities.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Tracking Information on the Web
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        For statistical purposes, we collect information on website activity
        such as the number of users who visit our websites, the date and time of
        visits, the number of pages viewed, navigation patterns, what country
        and what systems users have used to access the site and, when entering
        one of our websites from another website, the address of that website.
        This information is obtained through the use of &lsquo;cookies&rsquo;
        (refer below for more information about &lsquo;cookies&rsquo;).
      </Text>
      <Text>
        Our websites may also contain links to other websites. While these links
        are provided for convenience, if you are navigating these sites please
        be aware that the information handling practices of the linked websites
        may differ from ours. While we try to link only to sites that share our
        high standards and respect for privacy, we are not able to guarantee the
        privacy practices of other websites.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Protecting Your Personal Information
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We will take reasonable steps to keep your personal information safe
        from loss, unauthorised activity, or other misuse. You must take care to
        protect your personal information. You should also notify us via our
        contact details listed below as soon as you become aware of any security
        breaches relating to your account.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Website Security
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Whenever personal information is sent via our website we use high
        security levels to protect it. These security levels are standard for
        internet banking and large scale e-commerce sites and involve the use
        high levels of encryption. The security level of a web page can be
        viewed by clicking on the internet browser&apos;s padlock or key icon.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Cookies
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        A &lsquo;cookie&rsquo; is a packet of information that allows a website
        to identify and interact more effectively with a computer. Cookies do
        not identify you, but they may record some browser and/or connection
        information. Cookies are widely used on websites to help with
        navigation, monitor usage and to help personalise your experience when
        you visit a website. We may use cookies to monitor use of this website,
        to provide you with access to tailored information and services from
        this website, and to serve you better when you return to this website.
      </Text>
      <Text>
        Your browser can be individually set to accept all cookies, reject all
        cookies, or notify when a cookie is sent. If cookies are rejected, there
        may be limits on how our websites can be used.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Website Advertisements
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        From time to time we place advertisements on other websites. This may
        involve installing a cookie on a computer when our advertisement is
        viewed. This simply allows us to keep track of how many unique visitors
        we have to our site and from what advertisements they entered.
      </Text>
      <Text>
        This then allows us to measure the effectiveness of different
        advertisements. The privacy policies of the websites which install the
        cookies should provide additional information about the use of cookies.
      </Text>
      <Text>
        To evaluate the effectiveness of our website advertising, we may use
        third parties to collect statistical data. No personal data is collected
        on these occasions.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Latitude Service Portal
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        When you register for access to our Latitude service portal (including
        our mobile app), the information collected is compared with the details
        we already have stored, such as your name, date of birth and card
        number, which we have previously collected from you so we can verify
        that you are the person seeking access to your personal information. If
        you do not provide the information requested, you will not be able to
        access the Latitude service portal.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Credit Reporting Policy
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        The Credit Reporting Privacy Code permits credit providers like Latitude
        to share information with credit reporting agencies, on an ongoing
        basis. This means that Latitude may provide credit reporting agencies
        with certain credit related information about its customers’ accounts,
        such as:
      </Text>
      <List dotColor={COLOR.GREY75}>
        <ListItem>type of credit account e.g. credit card</ListItem>
        <ListItem>amount of credit extended (but not current balance)</ListItem>
        <ListItem>
          status of account e.g. open (including account open date)
        </ListItem>
        <ListItem>
          details of credit provider i.e. the lender who provided the credit
        </ListItem>
        <ListItem>
          monthly reporting of repayment history e.g. whether an account was
          paid on time, and
        </ListItem>
        <ListItem>
          changes in personal details such as address, contact details, etc.
        </ListItem>
      </List>
      <Text>
        For further information on comprehensive credit reporting, please see the
        following website&nbsp;
        <Link
          href="https://www.privacy.org.nz/privacy-act-2020/codes-of-practice/crpc2020/"
          target="_blank"
          rel="noopener noreferrer"
          css="color: #000; border-bottom: 0px #000;"
        >
          www.privacy.org.nz/privacy-act-2020/codes-of-practice/crpc2020/
        </Link>
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Contact Us
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Below are the contact numbers for the products and services offered by
        Latitude Financial Services and its related entities.
      </Text>
      <Text>
        <table style={{ width: '300px' }}>
          <thead>
            <th>Product</th>
            <th>Phone Number</th>
          </thead>
          <tbody>
            <tr>
              <td>Personal Loan</td>
              <td>
                <Link
                  href="tel:0800549454"
                  target="_blank"
                  rel="noopener noreferrer"
                  css="color: #000; border-bottom: 0px #000;"
                >
                  0800 549 454
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </Text>
    </Vertical>
  </Box.Section>
);

const StyledFooter = styled.footer`
  padding: ${PADDING.P24} 0 ${PADDING.P32};
  background-color: ${COLOR.GREY5};
  font-size: ${FONT_SIZE.SMALL};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: ${PADDING.P32};
  }
`;

const StyledDisclaimerText = styled.p`
  text-align: ${ALIGN.CENTER};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 7px;
  }
`;

const StyledFooterInner = styled.div`
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  @media (min-width: ${BREAKPOINT.LG}) {
    display: ${DISPLAY.FLEX};
    flex-direction: ${FLEX_DIRECTION.ROW};
  }
`;

export default Privacy;
